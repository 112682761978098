const LinkObfuscation = class {
  init() {
    const links = document.querySelectorAll('.obf[data-obf]');
    for (let i = 0; i < links.length; i++) {
      links[i].addEventListener('click', this.handleClick, false);
    }
  }

  handleClick(event) {
    const attribute = event.target.dataset.obf;
    if (event.ctrlKey) {
      const newWindow = window.open(
        decodeURIComponent(atob(attribute)),
        '_blank'
      );
      newWindow.focus();
    } else {
      document.location.href = decodeURIComponent(atob(attribute));
    }
  }
};

export default LinkObfuscation;
