const SPEED = 40;

class TitleScroll {
  constructor() {
    this.listeners = [];
    this.bottom = [];
  }

  init() {
    const observer = new IntersectionObserver(
      (entries) =>
        entries.forEach((entry) => {
          const observerId = entry.target.dataset.observerId;
          if (entry.isIntersecting) {
            this.listeners[observerId] = () => this.onScroll(entry.target);
            window.addEventListener('scroll', this.listeners[observerId]);
          } else {
            window.removeEventListener('scroll', this.listeners[observerId]);
          }
        }),
      {
        rootMargin: '0px',
      }
    );

    Array.from(document.querySelectorAll('.main-title__backdrop')).forEach(
      (target) => {
        target.dataset.observerId = (Math.random() + 1)
          .toString(36)
          .substring(7);
        observer.observe(target);
      }
    );
  }

  /**
   * @param {HTMLElement} target
   */
  onScroll(target) {
    const observerId = target.dataset.observerId;
    const scrollTop = target.getBoundingClientRect().top;

    if ('undefined' === typeof this.bottom[observerId]) {
      this.bottom[observerId] = scrollTop + 100;
    }

    const scrollAndSpeed = (this.bottom[observerId] - scrollTop) / SPEED;

    target.style.transform = `scalex(${Math.min(
      Math.max(scrollAndSpeed, 1),
      10
    )})`;
  }
}

export default TitleScroll;
