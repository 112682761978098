const Video = class {
  init() {
    if (!!window.IntersectionObserver) {
      let observer = new IntersectionObserver(
        (entries, observer) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              this.initVideo(entry.target);
              observer.unobserve(entry.target);
            }
          });
        },
        { rootMargin: '0px 0px -500px 0px' }
      );
      document.querySelectorAll('.js-cms-video').forEach((element) => {
        observer.observe(element);
      });
    }
  }

  initVideo(element) {
    if (element.dataset.videoType === 'youtube') {
      const iframe = this.getYoutubeIframe(element);
      element.parentElement.replaceChild(iframe, element);
    }
  }

  getYoutubeIframe(element) {
    const videoId = element.dataset.videoId;
    const iframe = document.createElement('iframe');
    iframe.src = `https://www.youtube-nocookie.com/embed/${videoId}`;
    iframe.width = '100%';
    iframe.height = '400px';
    iframe.style.border = '0';
    iframe.modestbranding = '1';

    return iframe;
  }
};

export default Video;
