import './commons';
import '@/scss/btgc.scss';

// JS specifics to BTGC goes here

import TitleScroll from './js/TitleScroll';
import MegaMenu from './js/MegaMenu';
import ScrollToTop from './js/ScrollToTop';
import MobileMenu from './js/MobileMenu';

new TitleScroll().init();
new MegaMenu().init();
new ScrollToTop().init();
new MobileMenu().init('btgc');
