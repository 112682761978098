export default class ScrollToTop {
  init() {
    const target = document.querySelector('header');
    const buttonClass = 'scroll-to-top';
    const buttonVisibleClass = `${buttonClass}--visible`;
    const button = document.querySelector(`.${buttonClass}`);

    button.addEventListener('click', () => {
      document.documentElement.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    });

    new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          button.classList.remove(buttonVisibleClass);
        } else {
          button.classList.add(buttonVisibleClass);
        }
      });
    }).observe(target);
  }
}
