const HEADER_ROW_CLASS = 'header-row';
const HEADER_ROW_COLLAPSED_CLASS = `${HEADER_ROW_CLASS}--collapsed`;
const HEADER_CELL_CLASS = 'header-cell';
const HEADER_CELL_COLLAPSED_CLASS = `${HEADER_CELL_CLASS}--collapsed`;
const ProductTabsTable = class {
  init() {
    this.initTableRowHeader();
    this.initProductSelector();
  }

  initProductSelector() {
    const slideClass = 'product-tabs-table__slide';
    const tableClass = 'product-tabs-table__table-wrapper';
    const selectedSlideClass = `${slideClass}--selected`;
    const selectedTableClass = `${tableClass}--selected`;

    const slides = document.querySelectorAll(`.${slideClass}`);
    const tables = document.querySelectorAll(`.${tableClass}`);

    if (slides === undefined || slides.length <= 0) {
      return;
    }
    if (tables === undefined || tables.length <= 0) {
      return;
    }

    slides[0].classList.add(selectedSlideClass);
    tables[0].classList.add(selectedTableClass);

    slides.forEach((s) => {
      s.addEventListener('click', () => {
        const selectedSlide = document.querySelector(`.${selectedSlideClass}`);
        const selectedSlideIndex = Array.from(slides).indexOf(selectedSlide);

        const newIndex = Array.from(slides).indexOf(s);

        if (newIndex !== selectedSlideIndex) {
          slides[selectedSlideIndex].classList.remove(selectedSlideClass);
          tables[selectedSlideIndex].classList.remove(selectedTableClass);

          slides[newIndex].classList.add(selectedSlideClass);
          tables[newIndex].classList.add(selectedTableClass);
        }
      });
    });
  }

  initTableRowHeader() {
    const table = document.querySelectorAll('table');
    table.forEach((t) => {
      t.style.height = '';
      t.style.borderCollapse = '';
    });

    // Select evey tr lines of the table
    const rows = document.querySelectorAll('table tr');

    // Browsering lines to add click listeners
    rows.forEach((row) => {
      const firstTd = row.querySelector('td:first-child');

      // If this line have only one child
      if (firstTd && row.children.length === 1) {
        row.classList.add(HEADER_ROW_CLASS); // Add header class on row
        row.style.height = '';

        row.children[0].classList.add(HEADER_CELL_CLASS); // Add header class on cell
        row.children[0].style.width = '';
        row.children[0].style.height = '';

        // Add the click listener
        firstTd.addEventListener('click', () => {
          this.toggleRowsVisibility(row);
        });
      }
    });
  }

  toggleRowsVisibility(clickedRow) {
    const rows = document.querySelectorAll(
      '.product-tabs-table__table-wrapper--selected table tr'
    );
    const rowsToToggle = [];

    // Find index of selected line
    const clickedRowIndex = Array.from(clickedRow.parentNode.children).indexOf(
      clickedRow
    );

    // Browsing lines between table headers
    for (let i = clickedRowIndex + 1; i < rows.length; i++) {
      const currentRow = rows[i];

      // Si la ligne est une entête, arrêtez le parcours
      if (currentRow.classList.contains(HEADER_ROW_CLASS)) {
        break;
      }

      rowsToToggle.push(currentRow);
    }

    // Toggle lines visibility
    rowsToToggle.forEach((row) => {
      row.style.display =
        row.style.display !== 'none' || row.style.display === ''
          ? // jshint ignore:line
            'none'
          : 'table-row';
    });

    if (
      clickedRow.children[0].classList.contains(HEADER_CELL_COLLAPSED_CLASS)
    ) {
      clickedRow.children[0].classList.remove(HEADER_CELL_COLLAPSED_CLASS);
      clickedRow.classList.remove(HEADER_ROW_COLLAPSED_CLASS);
    } else {
      clickedRow.children[0].classList.add(HEADER_CELL_COLLAPSED_CLASS);
      clickedRow.classList.add(HEADER_ROW_COLLAPSED_CLASS);
    }
  }
};

export default ProductTabsTable;
