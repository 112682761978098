const ResponsiveMenu = class {
  init() {
    this.breakpoint = 1024;
    this.mediaQuery = window.matchMedia(`(max-width: ${this.breakpoint}px)`);
    this.elementsToMove = [
      {
        identifier: 'js-pre-header',
        largeScreenContainer: 'js-header-container',
        largeScreenNextElement: 'js-main-header-part',
        smallScreenContainer: 'js-menu-mobile-first-strate',
        smallScreenNextElement: null,
      },
      {
        identifier: 'js-header-logo',
        largeScreenContainer: 'js-main-header-part',
        largeScreenNextElement: 'js-main-header-part-contact',
        smallScreenContainer: 'js-menu-mobile-head',
        smallScreenNextElement: 'main-menu',
      },
      {
        identifier: 'js-header-search',
        largeScreenContainer: 'js-main-header-part-search',
        largeScreenNextElement: null,
        smallScreenContainer: 'search-block',
        smallScreenNextElement: null,
      },
    ];

    if (this.mediaQuery.matches) {
      this.setSmallScreensDisplay();
    }

    this.listenScreenSizeChange();
  }

  listenScreenSizeChange() {
    this.mediaQuery.onchange = (event) => {
      if (event.matches) {
        this.setSmallScreensDisplay();
      } else {
        this.setLargeScreensDisplay();
      }
    };
  }

  setLargeScreensDisplay() {
    this.updateDisplay('largeScreen');
  }

  setSmallScreensDisplay() {
    this.updateDisplay('smallScreen');
  }

  updateDisplay(screenSizeKey) {
    this.elementsToMove.forEach((elementToMove) => {
      const element = document.getElementById(elementToMove.identifier);
      const destinationParentElement = document.getElementById(
        elementToMove[`${screenSizeKey}Container`]
      );
      if (elementToMove[`${screenSizeKey}NextElement`]) {
        const destinationNextElement = document.getElementById(
          elementToMove[`${screenSizeKey}NextElement`]
        );
        destinationParentElement.insertBefore(element, destinationNextElement);
      } else {
        destinationParentElement.appendChild(element);
      }
    });
  }
};

export default ResponsiveMenu;
