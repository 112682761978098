const MobileMenu = class {
  init(theme = null) {
    this.initMainMenuOpener(theme);
    this.initSecondStrateOpener();
    this.initThirdStrateOpener();
    this.initFourthStrateOpener();
    this.initBackButtonCloser();
    this.initSearchMenuOpener(theme);
    this.changeProductMenuByComponent();
    this.displayThirdStrateMenusUnderSecondStrateItems();
    this.displayFourthStrateMenusUnderThirdStrateItems();
  }

  changeProductMenuByComponent() {
    let firstItem = document.querySelector(
      '.menu-mobile__first-strate > .menu-mobile__item'
    );
    if (!firstItem) {
      return;
    }
    let firstItemId = firstItem.getAttribute('id');
    let firstMenuInner = document.querySelector(
      `.menu-mobile__second-strate[data-menu-id="${firstItemId}"] .menu-mobile__submenu-inner`
    );

    if (!firstMenuInner) {
      return;
    }

    let menuComponent = document.querySelector('.menu-mobile__menu-products');
    if (menuComponent) {
      let menuProductList = menuComponent.querySelector(
        '.menu__item .menu__submenu-list'
      );
      let menuProductButtons = menuComponent.querySelector(
        '.menu__item .menu__group-buttons'
      );

      firstMenuInner.insertAdjacentElement('afterend', menuProductList);
      menuProductList.insertAdjacentElement('afterend', menuProductButtons);
      firstMenuInner.remove();
      menuComponent.remove();
    }
  }

  displayThirdStrateMenusUnderSecondStrateItems() {
    const secondMenusItems = document.querySelectorAll(
      '.menu-mobile__second-strate .menu-mobile__submenu-item'
    );
    this.displayMenuUnderItem(secondMenusItems, 'third');
  }

  displayFourthStrateMenusUnderThirdStrateItems() {
    const thirdMenusItems = document.querySelectorAll(
      '.menu-mobile__third-strate .menu-mobile__third-menu-item'
    );
    this.displayMenuUnderItem(thirdMenusItems, 'fourth');
  }

  displayMenuUnderItem(items, menuLevel) {
    items.forEach((item) => {
      item.addEventListener('click', () => {
        let itemId = item.getAttribute('id');
        let relatedMenu = document.querySelector(
          `.menu-mobile__${menuLevel}-strate[data-menu-id="${itemId}"]`
        );
        if (relatedMenu !== null) {
          item.insertAdjacentElement('afterend', relatedMenu);
        }
      });
    });
  }

  initSearchMenuOpener(theme = null) {
    const mainButton = document.getElementById('search-button');
    const searchBlock = document.getElementById('search-block');

    if (mainButton) {
      mainButton.addEventListener('click', function () {
        if (
          searchBlock.classList.contains('menu-mobile__search-block--active')
        ) {
          searchBlock.classList.remove('menu-mobile__search-block--active');
          mainButton.classList.toggle('icon--magnifier');
          mainButton.classList.toggle('icon--close');
          mainButton.classList.remove('menu-mobile__search-button--active');
        } else {
          searchBlock.classList.add('menu-mobile__search-block--active');
          mainButton.classList.toggle('icon--magnifier');
          mainButton.classList.toggle('icon--close');
          mainButton.classList.add('menu-mobile__search-button--active');
          if (theme === 'elro') {
            const menuBurgerButton = document.querySelector(
              '.menu-mobile__burger'
            );
            const menuBurgerContent = document.querySelector(
              '.menu-mobile__first-strate'
            );
            if (menuBurgerButton === null || menuBurgerContent === null) {
              return;
            }
            menuBurgerButton.classList.remove('menu-mobile__burger--active');
            menuBurgerContent.classList.remove(
              'menu-mobile__first-strate--active'
            );
          }
        }
      });
    }
  }

  initMainMenuOpener(theme = null) {
    const mainButton = document.getElementById('main-menu');

    if (mainButton) {
      mainButton.addEventListener(
        'click',
        this.mainMenuOpener(
          mainButton,
          'main-menu',
          'menu-mobile__first-strate--active',
          'menu-mobile__burger--active',
          theme
        )
      );
    }
  }

  initSecondStrateOpener() {
    const openers = document.getElementsByClassName(
      'js-menu-mobile__has-submenu'
    );
    const menus = document.getElementsByClassName('menu-mobile__second-strate');
    for (let opener of Array.from(openers)) {
      opener.addEventListener(
        'click',
        this.masterOpenHandler(
          opener,
          menus,
          'menu-mobile__second-strate--active'
        )
      );
    }
  }

  initThirdStrateOpener() {
    const openers = document.getElementsByClassName(
      'js-menu-mobile__has-third-menu'
    );
    const menus = document.getElementsByClassName('menu-mobile__third-strate');
    for (let opener of Array.from(openers)) {
      opener.addEventListener(
        'click',
        this.masterOpenHandler(
          opener,
          menus,
          'menu-mobile__third-strate--active'
        )
      );
    }
  }

  initFourthStrateOpener() {
    const openers = document.getElementsByClassName(
      'js-menu-mobile__has-fourth-menu'
    );
    const menus = document.getElementsByClassName('menu-mobile__fourth-strate');
    for (let opener of Array.from(openers)) {
      opener.addEventListener(
        'click',
        this.masterOpenHandler(
          opener,
          menus,
          'menu-mobile__fourth-strate--active'
        )
      );
    }
  }

  initBackButtonCloser() {
    const openers = document.getElementsByClassName('menu-mobile__opener');
    const backButton = document.getElementsByClassName('menu-mobile__back');
    for (let button of Array.from(backButton)) {
      button.addEventListener('click', this.masterBackHandler(button, openers));
    }
  }

  clearOpenedMenus(menusList) {
    for (let menu of Array.from(menusList)) {
      if (menu.classList.contains('menu-mobile__second-strate--active')) {
        menu.classList.remove('menu-mobile__second-strate--active');
      }
      if (menu.classList.contains('menu-mobile__third-strate--active')) {
        menu.classList.remove('menu-mobile__third-strate--active');
      }
    }
  }

  clearOpeners(openersList) {
    for (let opener of Array.from(openersList)) {
      if (opener.classList.contains('menu-mobile__second-strate--active')) {
        opener.classList.remove('menu-mobile__second-strate--active');
      }
      if (opener.classList.contains('menu-mobile__third-strate--active')) {
        opener.classList.remove('menu-mobile__third-strate--active');
      }
    }
  }

  masterBackHandler(button, openers) {
    return () => {
      if (button.classList.contains('menu-mobile__back-main')) {
        const openedMenus = document.getElementsByClassName(
          'menu-mobile__second-strate'
        );
        for (let menu of Array.from(openedMenus)) {
          if (menu.classList.contains('menu-mobile__second-strate--active')) {
            menu.classList.remove('menu-mobile__second-strate--active');
          }
        }
      }
      if (button.classList.contains('menu-mobile__back-sub')) {
        const openedMenus = document.getElementsByClassName(
          'menu-mobile__third-strate'
        );
        for (let menu of Array.from(openedMenus)) {
          if (menu.classList.contains('menu-mobile__third-strate--active')) {
            menu.classList.remove('menu-mobile__third-strate--active');
          }
        }
      }
      if (button.classList.contains('menu-mobile__back-third')) {
        const openedMenus = document.getElementsByClassName(
          'menu-mobile__fourth-strate'
        );
        for (let menu of Array.from(openedMenus)) {
          if (menu.classList.contains('menu-mobile__fourth-strate--active')) {
            menu.classList.remove('menu-mobile__fourth-strate--active');
          }
        }
      }
      this.clearOpeners(openers);
    };
  }

  masterOpenHandler(opener, menusList, activeClass) {
    return () => {
      if (opener.classList.contains(activeClass)) {
        this.closeMenuHandler(opener.id, activeClass);
      } else {
        this.clearOpenedMenus(menusList);
        this.openMenuHandler(opener.id, activeClass);
        opener.classList.add(activeClass);
        let listOpeners = document.querySelectorAll(`.${activeClass}`);
        listOpeners.forEach((item) => {
          if (item === opener || item.dataset.menuId === opener.id) {
            return;
          }
          item.classList.remove(activeClass);
        });
      }
    };
  }

  openMenuHandler(menuId, activeClass) {
    document
      .querySelector(`[data-menu-id=${menuId}]`)
      .classList.add(activeClass);
  }

  closeMenuHandler(menuId, activeClass) {
    document
      .querySelector(`[data-menu-id=${menuId}]`)
      .classList.remove(activeClass);
    document.getElementById(menuId).classList.remove(activeClass);
  }

  mainMenuOpener(button, menuId, activeClass, buttonActiveClass, theme = null) {
    return () => {
      if (button.classList.contains('menu-mobile__burger--active')) {
        this.closeMenuHandler(menuId, activeClass);
        button.classList.remove(buttonActiveClass);
        this.clearOpenedMenus(
          document.getElementsByClassName('menu-mobile__third-strate')
        );
        this.clearOpenedMenus(
          document.getElementsByClassName('menu-mobile__second-strate')
        );
        this.clearOpeners(
          document.getElementsByClassName('js-menu-mobile__has-third-menu')
        );
        this.clearOpeners(
          document.getElementsByClassName('js-menu-mobile__has-submenu')
        );
        if (theme === 'btgc') {
          this.toggleSearchButton();
        }
      } else {
        this.openMenuHandler(menuId, activeClass);
        button.classList.add(buttonActiveClass);
        if (theme === 'btgc') {
          this.toggleSearchButton();
        } else if (theme === 'elro') {
          const menuBurgerButton = document.querySelector(
            '.menu-mobile__search-button'
          );
          const menuBurgerContent = document.querySelector(
            '.menu-mobile__search-block'
          );
          if (menuBurgerButton === null || menuBurgerContent === null) {
            return;
          }
          menuBurgerButton.classList.remove('icon--close');
          menuBurgerButton.classList.add('icon--magnifier');
          menuBurgerButton.classList.remove(
            'menu-mobile__search-button--active'
          );
          menuBurgerContent.classList.remove(
            'menu-mobile__search-block--active'
          );
        }
      }
    };
  }
  toggleSearchButton() {
    let searchButton = document.getElementById('search-button');
    if (searchButton.classList.contains('menu-mobile__search-button--hidden')) {
      searchButton.classList.remove('menu-mobile__search-button--hidden');
    } else {
      searchButton.classList.add('menu-mobile__search-button--hidden');
    }
  }
};

export default MobileMenu;
