import Swiper from 'swiper/swiper-bundle';

const Slider = class {
  init() {
    Array.from(
      document.querySelectorAll('.slider-image__swiper-container')
    ).forEach(
      (element) =>
        new Swiper(element, {
          // Optional parameters
          direction: 'horizontal',

          // If we need pagination
          pagination: {
            el: '.swiper-pagination',
          },

          // Navigation arrows
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
        })
    );

    Array.from(
      document.querySelectorAll('.slider-news__swiper-container')
    ).forEach(
      (element) =>
        new Swiper(element, {
          // Optional parameters
          direction: 'horizontal',
          slidesPerView: 1,
          centeredSlides: true,

          // Navigation arrows
          navigation: {
            nextEl: '.swiper-navigation--next.slider-news__nav',
            prevEl: '.swiper-navigation--prev.slider-news__nav',
          },

          pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            enabled: true,
            clickable: true,
            renderBullet: function (index, className) {
              return `<button class="slider-news__pagination swiper-pagination__bullet ${className}" aria-label="Navigate to slide ${
                index + 1
              }"></button>`;
            },
          },

          breakpoints: {
            375: {
              centeredSlides: true,
              slidesPerView: 1,
            },
            768: {
              centeredSlides: false,
              slidesPerView: 2,
              spaceBetween: 10,
            },
            1060: {
              centeredSlides: false,
              spaceBetween: 40,
              slidesPerView: 2,
            },
          },
        })
    );

    Array.from(
      document.querySelectorAll('.slider-projects__swiper-container')
    ).forEach(
      (element) =>
        new Swiper(element, {
          // Optional parameters
          direction: 'horizontal',
          slidesPerView: 1,

          // Navigation arrows
          navigation: {
            nextEl: '.swiper-navigation--next.slider-projects__nav',
            prevEl: '.swiper-navigation--prev.slider-projects__nav',
          },
          breakpoints: {
            768: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            1270: {
              slidesPerView: 3,
              spaceBetween: 40,
            },
          },
        })
    );

    Array.from(document.querySelectorAll('.tabs-slider__slider')).forEach(
      (element) =>
        new Swiper(element, {
          // Optional parameters
          direction: 'horizontal',
          slidesPerView: 1,
          spaceBetween: 20,
          observeParents: true,
          observer: true,

          // Navigation arrows
          navigation: {
            nextEl: '.swiper-navigation--next.tabs-slider__nav',
            prevEl: '.swiper-navigation--prev.tabs-slider__nav',
          },

          breakpoints: {
            1024: {
              spaceBetween: 55,
            },
          },
        })
    );

    Array.from(document.querySelectorAll('.slider-cms')).forEach(
      (element) =>
        new Swiper(element, {
          // Navigation arrows
          navigation: {
            nextEl: '.swiper-navigation--next.slider-cms__nav',
            prevEl: '.swiper-navigation--prev.slider-cms__nav',
          },
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
            renderBullet: function (index, className) {
              return `<button class="slider-cms__pagination swiper-pagination__bullet ${className}" aria-label="Navigate to slide ${
                index + 1
              }"></button>`;
            },
          },
        })
    );

    Array.from(
      document.querySelectorAll('.inline-product-list__slider-container')
    ).forEach(
      (element) =>
        new Swiper(element, {
          // Optional parameters
          direction: 'horizontal',
          slidesPerView: 1,
          // Navigation arrows
          navigation: {
            nextEl: '.inline-product-list__slider-nav.swiper-navigation--next',
            prevEl: '.inline-product-list__slider-nav.swiper-navigation--prev',
          },
          breakpoints: {
            530: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            1270: {
              slidesPerView: 4,
              spaceBetween: 40,
            },
          },
        })
    );

    Array.from(
      document.querySelectorAll('.product-images__slider-container')
    ).forEach(
      (element) =>
        new Swiper(element, {
          // Optional parameters
          direction: 'horizontal',
          slidesPerView: 'auto',
          centeredSlides: true,
          spaceBetween: 10,
          watchSlidesVisibility: true,
          // Navigation arrows
          navigation: {
            nextEl: '.product-images__nav.swiper-navigation--next',
            prevEl: '.product-images__nav.swiper-navigation--prev',
          },
          breakpoints: {
            480: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            580: {
              centeredSlides: false,
              spaceBetween: 10,
            },
            768: {
              slidesPerView: 3,
              centeredSlides: false,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 40,
              centeredSlides: false,
            },
          },
          on: {
            slideChangeTransitionStart: () => {
              const visibleSlideClass = 'swiper-slide-visible';
              const lastVisibleSlideClass = `${visibleSlideClass}--last`;

              document
                .querySelectorAll(`.${lastVisibleSlideClass}`)
                .forEach((slide) =>
                  slide.classList.remove(lastVisibleSlideClass)
                );

              const visibleSlides = document.querySelectorAll(
                `.${visibleSlideClass}`
              );
              visibleSlides[visibleSlides.length - 1].classList.add(
                lastVisibleSlideClass
              );
            },
          },
        })
    );

    const breakpoint = 1024;
    const selector = '.js-slider-product-images-main';
    const element = document.querySelector(selector);
    if (element) {
      const certificationSlide =
        document.querySelector(selector).dataset.certificationSlide;
      const productThumbsSlider = new Swiper(
        '.js-slider-product-images-thumbnails',
        {
          spaceBetween: 20,
          slidesPerView: 3,
          freeMode: true,
          watchSlidesVisibility: true,
          watchSlidesProgress: true,
          breakpoints: {
            [breakpoint]: {
              spaceBetween: 12,
            },
          },
        }
      );
      const productMainSlider = new Swiper(selector, {
        // Optional parameters
        direction: 'horizontal',
        slidesPerView: 1,
        spaceBetween: 20,
        watchSlidesVisibility: true,
        // Navigation arrows
        navigation: {
          nextEl: '.slider-product-images__nav.swiper-navigation--next',
          prevEl: '.slider-product-images__nav.swiper-navigation--prev',
        },
        thumbs: {
          swiper: productThumbsSlider,
        },
      });
      const toggleCertificationSlide = (matches, preventRemove = false) => {
        if (matches) {
          productThumbsSlider.addSlide(0, certificationSlide);
          productMainSlider.addSlide(0, certificationSlide);
        } else {
          if (!preventRemove) {
            productThumbsSlider.removeSlide(0);
            productMainSlider.removeSlide(0);
          }
        }
      };
      const mediaQuery = window.matchMedia(`(max-width: ${breakpoint}px)`);
      mediaQuery.onchange = (event) => {
        toggleCertificationSlide(event.matches);
      };
      toggleCertificationSlide(mediaQuery.matches, true);
    }
  }
};

export default Slider;
