import 'normalize.css';
import 'intersection-observer';

// JS commons to BTGC and Elro goes here

import Menu from './js/Menu';
import Slider from './js/Slider';
import SelectTabs from './js/SelectTabs';
import ResponsiveMenu from './js/ResponsiveMenu';
import LinkObfuscation from './js/LinkObfuscation';
import Video from './js/Video';
import TarteAuCitron from './js/TarteAuCitron';
import ProductTabsTable from './js/ProductTabsTable';

new Menu().init();
new Slider().init();
new SelectTabs().init();
new ResponsiveMenu().init();
new LinkObfuscation().init();
new Video().init();
new TarteAuCitron().init();
new ProductTabsTable().init();
