const SelectTabs = class {
  init() {
    const selectMenu = document.querySelectorAll('.tabs-slider__select')[0];

    if (typeof selectMenu !== 'undefined') {
      let labelActiveClass = 'tabs-slider__label--active';
      let contentActiveClass = 'tabs-slider__tab-content--active';
      let tabs = document.getElementsByClassName('tabs-slider__tab-content');
      let desktopTabsLabels =
        document.getElementsByClassName('tabs-slider__label');
      document.addEventListener('click', function (e) {
        if (e.target.classList.contains('tabs-slider__label')) {
          let tabContent = document.getElementById(
            e.target.getAttribute('data-tab')
          );
          for (let tab of tabs) {
            tab.classList.remove(contentActiveClass);
          }
          for (let desktopTab of desktopTabsLabels) {
            desktopTab.classList.remove(labelActiveClass);
          }
          if (!e.target.classList.contains(labelActiveClass)) {
            tabContent.classList.add(contentActiveClass);
            e.target.classList.add(labelActiveClass);
          }
          tabContent.classList.add(contentActiveClass);
          e.target.classList.add(labelActiveClass);
        }
      });
      selectMenu.addEventListener('change', function () {
        for (let tab of tabs) {
          tab.classList.remove(contentActiveClass);
        }
        let tabToShow = document.getElementById(selectMenu.value);
        tabToShow.classList.add(contentActiveClass);
      });
      window.addEventListener('resize', function () {
        let activeTabContent =
          document.getElementsByClassName(contentActiveClass)[0];
        let activeTabIndex = activeTabContent.id.charAt(
          activeTabContent.id.length - 1
        );

        if (window.innerWidth < 1024) {
          if (activeTabContent) {
            selectMenu.selectedIndex = activeTabIndex;
          }
        } else {
          let activeDesktopLabel =
            document.getElementsByClassName(labelActiveClass)[0];
          let activeDesktopLabelId = parseInt(
            activeDesktopLabel
              .getAttribute('data-tab')
              .charAt(activeDesktopLabel.getAttribute('data-tab').length - 1)
          );
          if (selectMenu.selectedIndex !== activeDesktopLabelId) {
            for (let tab of desktopTabsLabels) {
              tab.classList.remove(labelActiveClass);
            }
            let labelToShow = document.querySelectorAll(
              `[data-tab=${activeTabContent.id}]`
            )[0];
            labelToShow.classList.add(labelActiveClass);
          }
        }
      });
    }
  }
};

export default SelectTabs;
