export default class TarteAuCitron {
  constructor() {
    const { googleTagManagerId } = window.tarteAuCitronConfig || {};

    this.googleTagManagerId = googleTagManagerId;
  }

  init() {
    window.tarteaucitron.init({
      privacyUrl: '' /* Privacy policy url */,

      hashtag: '#cookies-consent' /* Open the panel with this hashtag */,
      cookieName: 'cookies-consent' /* Cookie name */,

      orientation: 'middle' /* Banner position (top - bottom) */,

      groupServices: false /* Group services by category */,

      showAlertSmall: false /* Show the small banner on bottom right */,
      cookieslist: false /* Show the cookie list */,

      closePopup: false /* Show a close X on the banner */,

      showIcon: false /* Show cookie icon to manage cookies */,
      //"iconSrc": "", /* Optionnal: URL or base64 encoded image */
      iconPosition:
        'BottomRight' /* BottomRight, BottomLeft, TopRight and TopLeft */,

      adblocker: false /* Show a Warning if an adblocker is detected */,

      DenyAllCta: true /* Show the deny all button */,
      AcceptAllCta: true /* Show the accept all button when highPrivacy on */,
      highPrivacy: true /* HIGHLY RECOMMANDED Disable auto consent */,

      handleBrowserDNTRequest: false /* If Do Not Track == 1, disallow all */,

      removeCredit: true /* Remove credit link */,
      moreInfoLink: true /* Show more info link */,

      useExternalCss: true /* If false, the tarteaucitron.css file will be loaded */,
      useExternalJs: true /* If false, the tarteaucitron.js file will be loaded */,

      //"cookieDomain": ".my-multisite-domaine.fr", /* Shared cookie for multisite */

      readmoreLink: '' /* Change the default readmore link */,

      mandatory: true /* Show a message about mandatory cookies */,
    });

    this.addGoogleTagManagerService();
    this.whenReady(() => {
      this.setLogo();
      this.fixButtons();
    });
  }

  /**
   * @param {function} callback
   */
  whenReady(callback) {
    const root = document.getElementById('tarteaucitronRoot');

    if (root) {
      callback();
    } else {
      setTimeout(() => this.whenReady(callback), 100);
    }
  }

  setLogo() {
    const element = document.getElementById('tarteaucitronAlertBig');
    const child = document.createElement('div');
    const image = document.createElement('img');
    const logo = document.querySelector('#js-header-logo img');

    image.classList.add(`${element.id}__logo`);
    image.src = logo.getAttribute('data-inverted-url') || logo.src;

    child.classList.add(`${element.id}__container`);
    child.appendChild(image);

    element.insertBefore(child, element.children[0]);
  }

  fixButtons() {
    const buttonsWithoutClass = document.querySelectorAll(
      '#tarteaucitronRoot button:not([class])'
    );

    Array.from(buttonsWithoutClass).forEach((button) =>
      button.classList.add('tarteaucitron__button')
    );
  }

  addGoogleTagManagerService() {
    if (!this.googleTagManagerId) {
      return;
    }

    window.tarteaucitron.user.gtagUa = this.googleTagManagerId;
    (window.tarteaucitron.job = window.tarteaucitron.job || []).push('gtag');
  }
}
