const MegaMenu = class {
  init() {
    this.megamenu = document.querySelector('.js-mega-menu');
    this.initOpening();
    this.initN2Opening();
  }

  initOpening() {
    const opener = document.querySelector('.js-mega-menu-open');
    opener.addEventListener('click', () => {
      this.megamenu.classList.add('mega-menu--opened');
    });

    const closer = document.querySelector('.js-mega-menu-close');
    closer.addEventListener('click', () => {
      this.megamenu.classList.remove('mega-menu--opened');
      this.closeMenuN2Handler();
    });
  }

  initN2Opening() {
    const openers = document.querySelectorAll('.js-mega-menu-open-submenu');
    openers.forEach((opener) => {
      opener.addEventListener(
        'click',
        this.getOpenMenuN2Handler(opener.dataset.n1Index)
      );
    });

    document
      .querySelector('.js-mega-menu-back')
      .addEventListener('click', () => {
        this.closeMenuN2Handler();
      });
  }

  getOpenMenuN2Handler(menuId) {
    return () => {
      const subMenu = document.querySelector(
        `.js-mega-menu-submenu[data-parent-id="${menuId}"]`
      );
      subMenu.classList.add('mega-menu__list-n2-container--opened');

      this.megamenu.classList.add('mega-menu--n2-opened');
    };
  }

  closeMenuN2Handler() {
    this.megamenu.classList.remove('mega-menu--n2-opened');
    const openedMenu = document.querySelector(
      '.mega-menu__list-n2-container--opened'
    );
    if (openedMenu) {
      openedMenu.classList.remove('mega-menu__list-n2-container--opened');
    }
  }
};

export default MegaMenu;
