const Menu = class {
  init() {
    this.initSubmenuOpener();
    this.initSubSubmenuOpener();
  }

  initSubmenuOpener() {
    const openers = document.getElementsByClassName('js-open-submenu');
    for (let opener of Array.from(openers)) {
      opener.addEventListener(
        'mouseenter',
        this.getOpenMenuHandler(
          `[data-parent-id="${opener.dataset.menuId}"][data-menu-level="2"]`,
          'menu__submenu--opened'
        ),
        false
      );
      opener.addEventListener(
        'mouseleave',
        this.getCloseMenuHandler(
          `[data-parent-id="${opener.dataset.menuId}"][data-menu-level="2"]`,
          'menu__submenu--opened'
        ),
        false
      );
    }
  }

  initSubSubmenuOpener() {
    const openers = document.getElementsByClassName('js-open-sub-submenu');
    for (let opener of Array.from(openers)) {
      opener.addEventListener(
        'mouseenter',
        this.getOpenMenuHandler(
          `[data-menu-id="${opener.dataset.menuId}"][data-parent-id="${opener.dataset.itemId}"][data-menu-level="3"]`,
          'menu__sub-submenu--opened'
        ),
        false
      );
      opener.addEventListener(
        'mouseleave',
        this.getCloseMenuHandler(
          `[data-menu-id="${opener.dataset.menuId}"][data-parent-id="${opener.dataset.itemId}"][data-menu-level="3"]`,
          'menu__sub-submenu--opened'
        ),
        false
      );
    }
  }

  getOpenMenuHandler(menuSelector, openedClassName) {
    return () => {
      const subMenuToOpen = document.querySelector(menuSelector);
      if (subMenuToOpen) {
        subMenuToOpen.classList.add(openedClassName);
      }

      document.querySelector('body').classList.add('menu-opened');
    };
  }

  getCloseMenuHandler(menuSelector, openedClassName) {
    return () => {
      const subMenuToClose = document.querySelector(menuSelector);
      if (subMenuToClose) {
        subMenuToClose.classList.remove(openedClassName);
        document.querySelector('body').classList.remove('menu-opened');
      }
    };
  }
};

export default Menu;
